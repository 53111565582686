// client/src/App.js

import { Container} from 'react-bootstrap';
import { Outlet, Route, Routes } from 'react-router-dom';

import Landing from './components/Landing';
import TopProjects from './components/TopProjects';
import Experience from './components/Experience';
import Contact from './components/Contact';
import Footer from './components/Footer';
import About from './components/About'; // Import About component

import './App.css';


function Layout() {
  return (
    <>
      
      <Container className='m-0 p-0'>
        <Outlet />
      </Container>
      <Footer />
    </>
  );
}

function App() {
  return (
    <Routes>
      <Route path='/' element={<Layout />}>
        <Route index element={<Landing />} />
        <Route path='about' element={<About />} />
        <Route path='top-projects' element={<TopProjects />} />
        <Route path='experience' element={<Experience />} />
        <Route path='contact' element={<Contact />} />
      </Route>
    </Routes>
  );
}

export default App;
