import React, { useState } from 'react';
import Card from './Card';

const TopProject = () => {
  const [searchTerm, setSearchTerm] = useState('');

  const projects = [
    { title: "Discord Bot Community",github:'',  text: "Developed with Python, the bot was made for a client in to offer help. " 
    +"Customer will be able to get support creating a ticket, finding information easier with "+
    "better looking UIs and more.", imageUrl: "https://cdn3.iconfinder.com/data/icons/logos-and-brands-adobe/512/267_Python-512.png" },
    { title: "Forms Register User ExpressJs", text: "Forms created for customer input to register to a company. You can access the forms using this website: __", imageUrl: "https://www.outsourcing-web.com/wp-content/uploads/2018/09/c509c38cb89bcf556b2051222663f398.png" },
    { title: "E-commerce Website", text: "E-commerce simulator for customers to buy and sell items, using the same dynamics of normal stores with unique ids for items, prices and more...", imageUrl: "https://www.djangoproject.com/m/img/logos/django-logo-positive.png" },
    { title: "College Notebook App", text: "Notebook App made in ElectronJs, still in progress but able to use and to check, new features comming soon...", imageUrl: "https://miro.medium.com/v2/resize:fit:1200/1*O6KluMvEBZ1cBL3EPo4tig.png" },
    { title: "Data Visualizer Dashboard", text: "Dashboard created to visualize and process data, measuring NPS score for CX (Customer Experience) and displaying all information from excel/Database files. obs: the project language is in portuguese.", imageUrl: "https://cdn-images-1.medium.com/max/1200/1*v-bN7I2agChvO1khEr4WUA.png" },
    // Add more projects as needed
  ];

  const filteredProjects = projects.filter(project =>
    project.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className='top-project'>
      <input 
        type="text" 
        placeholder="Search projects..." 
        value={searchTerm} 
        onChange={(e) => setSearchTerm(e.target.value)} 
        className="search-bar"
      />
      <div className='card-container'>
        {filteredProjects.map((project, index) => (
          <Card 
            key={index} 
            title={project.title}
            github={project.github} 
            text={project.text} 
            imageUrl={project.imageUrl} 
            className="custom-card"
          />
        ))}
      </div>
    </div>
  );
};

export default TopProject;
