// client/src/components/Experience.js

import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

function Experience() {
  const experiences = [
    {
      image: 'https://i3.ypcdn.com/blob/910ed70ed2fcae8d339a440fa61feb1fada62726',
      company: 'Best Buy',
      role: 'Computer Sales Advisor',
      duration: 'Nov 2023 - Present',
      description: ' Provided technical support, troubleshooting, and guidance to customers on various computer-related issues.'
    },
    {
      image: 'https://raichu-uploads.s3.amazonaws.com/logo_justa_G1yKwU.png',
      company: 'Justa Soluções Financeiras',
      role: 'Data Scientist Junior',
      duration: 'Oct 2021 - Aug 2022',
      description: 'Conducted data analysis, automated tasks using bots, and built predictive models to optimize stock control.'
    }
  ];

  return (
    <Container>
      <h1 className='text-white title-work text-center'>Work Experience</h1>
      <div className="horizontal-line"></div>
      <Row>
        {experiences.map((exp, index) => (
          <Col key={index} md={12} className="mb-4">
            <div className="work-experience">
            {exp.image && <img src={exp.image} alt={`${exp.company} logo`} className="work-experience-image" />}
              <h5 className='text-white'>{exp.company}</h5>
              <p><strong className='text-white'>Role:</strong> {exp.role}</p>
              <p><strong className='text-white'>Duration:</strong> {exp.duration}</p>
              <p className='text-white'>- {exp.description}</p>
            </div>
            {index < experiences.length - 1 && <div className="horizontal-line"></div>}
          </Col>
        ))}
      </Row>
    </Container>
  );
}

export default Experience;
