// client/src/components/About.js

import React from 'react';

function About() {
  return (
    <div className='text-white landing-page2'>
      👋 Hello! I'm Felippe Nagy, a passionate Computer Scientist currently studying at Valencia College. With a strong foundation in Node, Python, ReactJS, and Back End Development, 
      I am committed to creating innovative solutions and achieving impactful results.
      Fluent in English, Portuguese, and Spanish, I bring strong communication and collaboration skills to every project.
      Feel free to explore my portfolio and get in touch!
      <br></br>   
      <h4 className='title-interest'><i className="fa-solid fa-thumbtack"></i> Interests:</h4>
      <p className='text-center interest-items'>Gaming, Programming, Science Fiction</p>
    </div>

  );
}

export default About;
